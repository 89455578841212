import './App.css';
import './contact.css'
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from './appBar';
import Background from './background';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';

import React from 'react'

function Contact() {
  const [drawerTrigger, setDrawertrigger] = useState(false);
  return (
    <div className='App'>
      <div className="glass"></div>
      <AppBar />
      <Background />
      <Drawer
        anchor={"left"}
        open={drawerTrigger}
        onClose={() => { setDrawertrigger(false) }}
        sx={{}}
      >
        <div className="mobile-logo">
          <a href="/">DermoStyles</a>
        </div>
        <div className="mobile-links">
          <a href="/" className="link">Acceuil</a>
          <a href="/products" className="link">Produits</a>
          <a href="/" className="link">Conseils</a>
          <a href="/contact" className="link">Contactez-nous</a>
        </div>
      </Drawer>

        <div className="form-container">
        <div className="form">
            <h2>Contactez-Nous</h2>
        <TextField id="outlined-basic" label="Email" className="email-field" variant="outlined" sx={{width: "50%",   marginTop: "30px",
    marginBottom: "30px"}}/>
        <TextField
          className="email-field"
          sx={{width: "50%", marginTop: "30px",
          marginBottom: "30px"}}
          label="Multiline"
          multiline
          rows={10}
          maxRows={10}
          defaultValue="Mettez Votre Commentaire !"
          variant="outlined"
        />
        <Button variant="contained" sx={{width: "50%",   marginTop: "30px",
    marginBottom: "50px", height: "80px", borderRadius:30, backgroundColor: "#91F2DB",transition:"all 250ms ease-in-out", "&:hover": {backgroundColor: "#78cab7", transform:"scale(1.05)"}}} >Envoyez Email</Button>
        </div>

        </div>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <a href="/">DermoStyles</a>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">
          <div className="quick-links">
            <h2>Liens Rapides :</h2>
            <div className="footer-links">
              <a href="/" className="link">Acceuil</a>
              <a href="/products" className="link">Produits</a>
              <a href="/" className="link">Conseils</a>
              <a href="/contact" className="link">Contactez-nous</a>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">

        </div>
      </div>
    </div>
  )
}

export default Contact
