import React from 'react';
import { Menu } from '@mui/icons-material';
import './App.css';

function AppBar(props) {
    var setDrawertrigger = props.trigger;
    return (
        <div className="appBar">
        <div className="logo">
         <a href="/">DermoStyles</a> 
        </div>
        <div className="links">
          <a href="/" className="link">Acceuil</a>
          <a href="/products" className="link">Produits</a>
          <a href="/" className="link">Conseils</a>
          <a href="/contact" className="link">Contactez-nous</a>
        </div>
        <div className="burger" onClick={() => {setDrawertrigger(true)}}>
          <Menu />
        </div>
      </div>
    )
}

export default AppBar
