import './App.css';
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import image1 from './assets/image1.png';
import { VerifiedUserOutlined } from '@mui/icons-material';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import AppBar from './appBar';
import Background from './background';
import { height } from '@mui/system';
import products from './data';

function App() {
  const [drawerTrigger, setDrawertrigger] = useState(false);
  return (
    <div className="App">
      <div className="glass"></div>
      <Background />
      <Drawer
        anchor={"left"}
        open={drawerTrigger}
        onClose={() => { setDrawertrigger(false) }}
        sx={{}}
      >
        <div className="mobile-logo">
          <a href="/">DermoStyles</a>
        </div>
        <div className="mobile-links">
          <a href="/" className="link">Acceuil</a>
          <a href="/products" className="link">Produits</a>
          <a href="/" className="link">Conseils</a>
          <a href="/contact" className="link">Contactez-nous</a>
        </div>
      </Drawer>
      <div className="img">
        <img src={image1} alt="" />
      </div>
      <AppBar trigger={setDrawertrigger} />
      <div className="content1">
        <div className="title-container">
          <h1>DermoStyles</h1>
          <h2>Du Soin Au Besoin</h2>
        </div>

        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque placeat, temporibus sapiente quis iste itaque expedita libero, nulla, repellendus dolores corrupti dolore! Ipsum officia ullam rerum non harum eum magnam! Lorem ipsum dolor sit amet consectetur adipisicing elit. Atque placeat, temporibus sapiente quis iste itaque expedita libero, nulla, repellendus dolores corrupti dolore! Ipsum officia ullam rerum non harum eum magnam!</p>
        <div className="contact-button">Contactez-nous</div>
      </div>
      <div className="caroussel"> 
        {products.map((product, index) => (
          <div key={index} style={{borderRadius: 30, overflow: "hidden"}}>
          <Document
          file={process.env.PUBLIC_URL + product.pdfURL}
          onLoadSuccess={(pageNumber) => {
              //on load
          }}
          options={{}}
          
        >
          <Page pageNumber={1} width={window.innerWidth * 0.3}/>
        </Document>
        </div>
        ))}
      </div>
      <div className="content2">
        <div className="card">
          <div className="card-icon">
            <VerifiedUserOutlined sx={{ color: "rgba(168, 218, 220, 1)", height: "200px" }} />
          </div>

          <h2>Testé</h2>
          <p>Test text Test text
            Test text Test text
            Test text </p>
        </div>
        <div className="card">
        <div className="card-icon">
            <VerifiedUserOutlined sx={{ color: "rgba(168, 218, 220, 1)", height: "200px" }} />
          </div>

          <h2>Testé</h2>
          <p>Test text Test text
            Test text Test text
            Test text </p>
        </div>
        <div className="card">
        <div className="card-icon">
            <VerifiedUserOutlined sx={{ color: "rgba(168, 218, 220, 1)", height: "200px" }} />
          </div>

          <h2>Testé</h2>
          <p>Test text Test text
            Test text Test text
            Test text </p>
        </div>
      </div>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <a href="/">DermoStyles</a>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">
          <div className="quick-links">
            <h2>Liens Rapides :</h2>
            <div className="footer-links">
              <a href="/" className="link">Acceuil</a>
              <a href="/products" className="link">Produits</a>
              <a href="/" className="link">Conseils</a>
              <a href="/contact" className="link">Contactez-nous</a>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">

        </div>
      </div>
    </div>
  );
}

export default App;
