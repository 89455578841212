import React from 'react'

function Background() {
    return (
        <div className="background">
              <div className="blob">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="blobSvg">
  <path d="M25.2,-47.1C33.8,-38.8,42.4,-34.2,43.9,-26.9C45.3,-19.7,39.6,-9.8,40,0.2C40.4,10.3,47,20.6,46.3,29.2C45.6,37.7,37.5,44.4,28.6,50.8C19.7,57.2,9.8,63.2,-2,66.8C-13.9,70.3,-27.8,71.3,-40.5,67C-53.1,62.8,-64.4,53.3,-73.2,41.2C-81.9,29.2,-88.1,14.6,-88,0.1C-87.9,-14.5,-81.5,-28.9,-68.6,-33.8C-55.8,-38.8,-36.4,-34.2,-23.9,-40.1C-11.4,-46.1,-5.7,-62.5,1.3,-64.9C8.4,-67.2,16.7,-55.3,25.2,-47.1Z" transform="translate(100 100)"/>
</svg>  
      </div>
      <div className="blob2">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200" className="blobSvg2">
  <path d="M25.2,-47.1C33.8,-38.8,42.4,-34.2,43.9,-26.9C45.3,-19.7,39.6,-9.8,40,0.2C40.4,10.3,47,20.6,46.3,29.2C45.6,37.7,37.5,44.4,28.6,50.8C19.7,57.2,9.8,63.2,-2,66.8C-13.9,70.3,-27.8,71.3,-40.5,67C-53.1,62.8,-64.4,53.3,-73.2,41.2C-81.9,29.2,-88.1,14.6,-88,0.1C-87.9,-14.5,-81.5,-28.9,-68.6,-33.8C-55.8,-38.8,-36.4,-34.2,-23.9,-40.1C-11.4,-46.1,-5.7,-62.5,1.3,-64.9C8.4,-67.2,16.7,-55.3,25.2,-47.1Z" transform="translate(100 100)"/>
</svg>  
      </div>
      
   
        </div>
    )
}

export default Background
