import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const options = {
    cMapUrl: 'cmaps/',
    cMapPacked: true,
  };

function ProductCard({ product }) {
    const [opendialog , setOpendialog] = useState(false);
    const [height, setHeight] = useState(200);

    const handleOpen = () => {
        setOpendialog(true);
    };
    const handleClose = () => {
        setOpendialog(false);
    }

    const style = {
        position: 'absolute',
        width: '80vw',
        height: 'auto',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    
        backgroundColor: 'white',
        borderRadius : 32,
        boxShadow: 24,
        p: 4,
      };
    return (
        <div className="product-card" onClick={() => {if(!opendialog) handleOpen();}}>
            <div className="product-image">
                <img src={process.env.PUBLIC_URL + product.img} />
            </div>
            <div className="product-title">{product.nom}</div>
            <div className="product-description">{product.description}</div>
            <Modal
                open={opendialog}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div style={style} className="product-page">
            <Document
        file={process.env.PUBLIC_URL + product.pdfURL}
        onLoadSuccess={(pageNumber) => {
            //on load
        }}
        options={options}
      >
        <Page pageNumber={2} width={window.innerWidth * 0.8}/>
      </Document>
            <div className="close-modal" onClick={handleClose}>X</div>
                </div>
            </Modal>
        </div>
    )
}
/* 

<div className="product-title-page">{product.nom}</div>
            <div className="product-description-page">{product.description}</div>
            */

export default ProductCard
