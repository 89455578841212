import './App.css';
import './Products.css'
import { useState } from 'react';
import Drawer from '@mui/material/Drawer';
import AppBar from './appBar';
import Background from './background';
import Grid from '@mui/material/Grid';

import React from 'react'
import ProductCard from './components/product-card';
import products from './data';

function Products() {
  const [drawerTrigger, setDrawertrigger] = useState(false);
  
  return (
    <div className='App'>
      <div className="glass"></div>
      <AppBar />
      <Background />
      <Drawer
        anchor={"left"}
        open={drawerTrigger}
        onClose={() => { setDrawertrigger(false) }}
        sx={{}}
      >
        <div className="mobile-logo">
          <a href="/">DermoStyles</a>
        </div>
        <div className="mobile-links">
          <a href="/" className="link">Acceuil</a>
          <a href="/products" className="link">Produits</a>
          <a href="/" className="link">Conseils</a>
          <a href="/contact" className="link">Contactez-nous</a>
        </div>
      </Drawer>

      <div className="products-container">
        <h2 className="products-header-title">Produits</h2>
        <div className="products-grid">
          <Grid container spacing={{ xs: 4,sd:3,  md: 4 }} columns={{ xs: 4, sm: 8, md: 8 , lg:12}}>
            {products.map((i, index) => (
              <Grid item xs={4} sm={8} md={4} lg={4} key={index}>
                <ProductCard product={{nom:i.name, description:i.description, img: i.img, pdfURL: i.pdfURL}}/>
              </Grid> 
            ))}
          </Grid>

        </div>
      </div>
      <div className="footer">
        <div className="footer-container">
          <div className="footer-logo">
            <a href="/">DermoStyles</a>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">
          <div className="quick-links">
            <h2>Liens Rapides :</h2>
            <div className="footer-links">
              <a href="/" className="link">Acceuil</a>
              <a href="/products" className="link">Produits</a>
              <a href="/" className="link">Conseils</a>
              <a href="/contact" className="link">Contactez-nous</a>
            </div>
          </div>
        </div>
        <div className="divider"></div>
        <div className="footer-container">

        </div>
      </div>
    </div>
  )
}

export default Products
