let products = [
    {
      name: "Colisylane",
      description:<div> <b>Description Test</b><br></br><p>Ceci est une description test</p></div>,
      img:"./assets/products/Colisylane Boite V1.png",
      pdfURL:"./assets/pdf/Colisylane.pdf"
    },
    {
      name: "DermoCalm",
      description:<div> <b>Description Test</b><br></br><p>Ceci est une description test</p></div>,
      img: "./assets/products/DermoCalm.png",
      pdfURL:"./assets/pdf/DermoCalm.pdf"
    },
    {
      name: "DermoCuivre",
      description:<div> <b>Description Test</b><br></br><p>Ceci est une description test</p></div>,
      img:"./assets/products/Dermocuivre V1.png",
      pdfURL:"./assets/pdf/DermoCuivre.pdf"
    },
    {
        name: "Phytogizone",
        description:<div> <b>Description Test</b><br></br><p>Ceci est une description test</p></div>,
        img:"./assets/products/Phytogizone.png",
        pdfURL:"./assets/pdf/Phytogizone.pdf"
      },
      {
        name: "Dermo HEC",
        description:<div> <b>Description Test</b><br></br><p>Ceci est une description test</p></div>,
        img:"./assets/products/HEC.png",
        pdfURL:"./assets/pdf/Dermo HEC.pdf"
      }
  ]

  export default products;